<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Input clearable placeholder="输入ip 添加" class="search-input" v-model="formAddIP.ip"/>
        <Button  @click="showaddIp" class="search-btn" type="primary">添加</Button>
      </div>
      <div style="text-align:left">
        <Form :model="queryinfo" :label-width="80">
          <Row>
            <Col span="4"><br>
              <FormItem label="状态">
                <Select v-model="queryinfo.is_right" clearable @on-change="queryinfoselect">
                  <Option :value="2">禁用</Option>
                  <Option :value="1">启用</Option>
                </Select>
              </FormItem>
            </Col>&ensp;
            <Col span="4"><br>
              <FormItem label="纠正ip查询">
                <Input v-model="queryinfo.ip" placeholder="请输入正确的IP" clearable @on-change="selectip" ></Input>
              </FormItem>
            </Col>&ensp;
          </Row>
        </Form>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
      <br />
      <template>
        <Page :current="queryinfo.page"
              :total="total"
              :page-size-opts="pagesizeopts"
              @on-change="changepage"
              @on-page-size-change="changepagesize"
              show-total
              show-sizer
              style="text-align: right;" />
      </template>
    </Card>
    <Drawer
        title="ip比较"
        v-model="value3"
        width="800"
        :mask-closable="false"
        :styles="styles">
      <Split v-model="split1">
        <div slot="left" class="demo-split-pane">IP纠正
          <Form :model="formData.ipcorrect">
            <Row :gutter="32">
              <Col span="12">
                <FormItem label="国家" label-position="top">
                  <Input v-model="formData.ipcorrect.country" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="行政区划代码" label-position="top">
                  <Input v-model="formData.ipcorrect.adcode" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="国家编码" label-position="top">
                  <Input v-model="formData.ipcorrect.areacode" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="自治域编码" label-position="top">
                  <Input v-model="formData.ipcorrect.asnumber" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="省份" label-position="top">
                  <Input v-model="formData.ipcorrect.province" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="城市" label-position="top">
                  <Input v-model="formData.ipcorrect.city" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="区/县" label-position="top">
                  <Input v-model="formData.ipcorrect.district" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="运营商" label-position="top">
                  <Input v-model="formData.ipcorrect.isp" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="IP所属机构名称" label-position="top">
                  <Input v-model="formData.ipcorrect.owner" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="时区" label-position="top">
                  <Input v-model="formData.ipcorrect.timezone" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="最小ip" label-position="top">
                  <Input v-model="formData.ipcorrect.ipmin" disabled />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="最大ip" label-position="top">
                  <Input v-model="formData.ipcorrect.ipmax" disabled />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="状态">
                  <Select v-model="formData.ipcorrect.is_right">
                    <Option :value="2">禁用</Option>
                    <Option :value="1">启用</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
        <div slot="right" class="demo-split-pane">默认ip
          <Form :model="formData.ipdefalut">
            <Row :gutter="32">
              <Col span="12">
                <FormItem label="国家" label-position="top">
                  <Input v-model="formData.ipdefalut.country" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="行政区划代码" label-position="top">
                  <Input v-model="formData.ipdefalut.adcode" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="国家编码" label-position="top">
                  <Input v-model="formData.ipdefalut.areacode" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="自治域编码" label-position="top">
                  <Input v-model="formData.ipdefalut.asnumber" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="省份" label-position="top">
                  <Input v-model="formData.ipdefalut.province" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="城市" label-position="top">
                  <Input v-model="formData.ipdefalut.city" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="区/县" label-position="top">
                  <Input v-model="formData.ipdefalut.district" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="运营商" label-position="top">
                  <Input v-model="formData.ipdefalut.isp" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="IP所属机构名称" label-position="top">
                  <Input v-model="formData.ipdefalut.owner" />
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="时区" label-position="top">
                  <Input v-model="formData.ipdefalut.timezone" />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
      </Split>
      <!--      <div class="demo-drawer-footer" style="position: relative;left:10%;">-->
      <!--        <Button style="margin-right: 8px" @click="value3 = false">Cancel</Button>-->
      <!--        <Button type="primary" @click="update(formData.ipcorrect)">保存</Button>-->
      <!--      </div>-->
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="value3 = false">关闭</Button>
        <Button type="primary" @click="update(formData.ipcorrect)">保存</Button>
      </div>
    </Drawer>
    <Modal
        v-model="showaddmodel"
        title="添加ip"
        @on-ok="addrightip"
        width="1000px"
        @on-cancel="cancel">
      <Form :model="ipAddInfo">
        <Row :gutter="32">
          <Col span="8">
            <FormItem label="最小ip" label-position="top">
              <Input v-model="ipAddInfo.ipmin" disabled />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="最大ip" label-position="top">
              <Input v-model="ipAddInfo.ipmax" disabled />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="洲" label-position="top">
              <Input v-model="ipAddInfo.continent" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="国家编码" label-position="top">
              <Input v-model="ipAddInfo.areacode" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="行政区编码" label-position="top">
              <Input v-model="ipAddInfo.adcode" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="国家" label-position="top">
              <Input v-model="ipAddInfo.country" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="省份" label-position="top">
              <Input v-model="ipAddInfo.province" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="城市" label-position="top">
              <Input v-model="ipAddInfo.city" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="区/县" label-position="top">
              <Input v-model="ipAddInfo.district" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="经度" label-position="top">
              <Input v-model="ipAddInfo.lngwgs" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="纬度" label-position="top">
              <Input v-model="ipAddInfo.latwgs" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="定位半径" label-position="top">
              <Input v-model="ipAddInfo.radius" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="定位精度" label-position="top">
              <Input v-model="ipAddInfo.accuracy" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="IP所属机构名称" label-position="top">
              <Input v-model="ipAddInfo.owner" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="运营商名称" label-position="top">
              <Input v-model="ipAddInfo.isp" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="自治域编码" label-position="top">
              <Input v-model="ipAddInfo.asnumber" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="定位方式" label-position="top">
              <Input v-model="ipAddInfo.source" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="邮编" label-position="top">
              <Input v-model="ipAddInfo.zipcode" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="时区" label-position="top">
              <Input v-model="ipAddInfo.timezone" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="状态">
              <Select v-model="ipAddInfo.is_right">
                <Option :value="2">禁用</Option>
                <Option :value="1">启用</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>

    </Modal>
    <Modal
        v-model="delip"
        title="ip删除框架"
        @on-ok="delok"
        @on-cancel="cancel">
      <p>确定删除该ip信息</p>
    </Modal>
  </div>
</template>

<script>
import {del, get, post, put} from "@/api/http";
import Notice from "@/components/baseNotice";
export default {
  name: "IpCorrect",
  data () {
    return {
      ruleformAddIP: {
        ip: [
          {message: '请输入IP', trigger: 'blur'},
          {type: 'string', message: '格式有误', trigger: 'blur', pattern: /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/}
        ]
      },
      split1: 0.5,
      columns: [
        {
          title: '状 态',
          key: 'is_right',
          width: 100,
          render: (h, {row}) => {
            let statusColor = {
              "2": ['禁用', '#ed4014'],
              "1": ['启用', '#19be6b']
            }
            return h('Badge',{
              props: {
                color: statusColor[row.is_right][1],
                text:  statusColor[row.is_right][0]
              },
            });
          }
        },
        {title: '国家', key: 'country'},
        {title: '最小ip', key: 'minip',width: 150,
          render: (h,{row}) =>{
            return h('div',this.int2ip(row.minip))
          }
        },
        {title: '最大ip', key: 'maxip',width: 150,
          render: (h,{row}) =>{
            return h('div',this.int2ip(row.maxip))
          }

        },
        {title: '城市', key: 'city'},
        {title: '区县', key: 'district'}, {title: '运营商', key: 'isp'},
        {title: 'IP所属机构', key: 'owner'}, {title: '省份', key: 'province'},
        {title: '时区', key: 'timezone'}, {title: '邮编', key: 'zipcode'},
        {title: '操 作', key: 'action', width: 150, align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.ipDiff(params.row)
                  }
                }
              }, 'IP对比 |'),
              h('a', {
                on: {
                  click: () => {
                    this.ipDelmodel(params.row)
                  }
                }
              }, '删 除')
            ]);
          }
        }
      ],
      queryinfo: {
        page: 1,
        pagesize: 10,
        is_right:"",
        ip: '',
      },
      data: [],
      delipinfo: {},
      pagesizeopts: [10, 30, 50, 100],
      total: 0,
      showaddmodel: false,
      value3: false,
      delip: false,
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      formData: {
        ipcorrect: {
          ipmin:'',
          ipmax:'',
        },
        ipdefalut: {}
      },
      ipAddInfo: {},
      formAddIP: {"ip":""},
      formselectIP: {"ip":""}
    }
  },
  methods: {
    int2ip(num) {
      var str;
      var tt = new Array();
      tt[0] = (num >>> 24) >>> 0;
      tt[1] = ((num << 8) >>> 24) >>> 0;
      tt[2] = (num << 16) >>> 24;
      tt[3] = (num << 24) >>> 24;
      str = String(tt[0]) + "." + String(tt[1]) + "." + String(tt[2]) + "." + String(tt[3]);
      return str;
    },

    getIpcorrect(queryinfo) {
      const url = "/paiip/api/v1/ipcorrect/ipinfo"
      get(url,queryinfo).then((resp)=>{
        this.data = resp.data;
        this.total = resp.count;
      })
    },

    queryinfoselect() {
      this.queryinfo.page = 1
      this.getIpcorrect(this.queryinfo)
    },

    ipDiff(data) {
      get(`/paiip/api/v1/ipcorrect/ipdiff?minip=`+data.minip + '&maxip=' + data.maxip)
          .then((resp)=>{
            this.value3 = true
            this.formData.ipcorrect = resp.data.ipcorrect
            this.formData.ipcorrect.ipmin = this.int2ip(resp.data.ipcorrect.minip)
            this.formData.ipcorrect.ipmax = this.int2ip(resp.data.ipcorrect.maxip)
            this.formData.ipdefalut = resp.data.ipdefalut
          })
    },
    ipDel(data) {
      const minip = data.minip
      const maxip = data.maxip
      del(`/paiip/api/v1/ipcorrect/delete?minip=`+ minip + '&maxip=' + maxip)
          .then((resp)=>{
            console.log(resp)
            this.queryinfo.page = 1
            this.getIpcorrect(this.queryinfo);
          }).catch(e=>{
        return e
      })
    },

    is_ip (value) {
      var exp=/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      var reg = value.match(exp);
      if(reg==null){
        this.$Message.error("IP地址不合法")
        return false;
      } else {
        return true
      }
    },

    checkIP (value) {
      var exp=/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      var reg = value.match(exp);
      if(reg==null){
        console.log("IP地址不合法")
        return false;
      } else {
        return true
      }
    },


    ipDelmodel (data) {
      this.delip = true
      this.delipinfo = data
    },

    update(data) {
      post("/paiip/api/v1/ipcorrect/ip", data)
          .then(()=>{
            this.getIpcorrect(this.queryinfo)
            Notice.success("提交操作", "保存成功")
            this.value3 = false
          })
          .catch((e)=>{
            return e
          })
    },
    showaddIp() {
      this.formAddIP.ip = this.formAddIP.ip.trim()
      if (this.is_ip(this.formAddIP.ip)) {
        const url = `/paiip/api/v1/ipcorrect/ipinfo?ip=` + this.formAddIP.ip
        get(url).then((resp)=>{
          if (resp.count === 0) {
            this.showaddmodel = true;
            this.searchip(this.formAddIP.ip)
          } else {
            this.$Message.error("已经存在")
          }
        })
      }
    },
    selectip () {
      this.queryinfo.ip = this.queryinfo.ip.trim()
      if (this.checkIP(this.queryinfo.ip)) {
        this.queryinfo.page = 1
        this.getIpcorrect(this.queryinfo)
      } else {
        this.queryinfo.ip = ""
        this.queryinfo.page = 1
        this.getIpcorrect(this.queryinfo)
      }
    },

    selectqueryinfo () {
      this.queryinfo.page = 1
      this.getIpcorrect(this.queryinfo)
    },

    searchip (ip) {
      get(`/paiip/api/v1/ipcorrect/defaultip?ip=`+ip)
          .then((resp)=>{
            this.ipAddInfo = resp.data
            this.ipAddInfo.is_right = 1
          }).catch(e=>{
        return e
      })
    },

    addrightip () {
      this.ipAddInfo["minip"] = parseInt(this.ipAddInfo["minip"])
      this.ipAddInfo["maxip"] = parseInt(this.ipAddInfo["maxip"])
      this.update(this.ipAddInfo)
    },
    cancel () {
      this.$Message.info('Clicked cancel');
    },
    changepage (page) {
      this.queryinfo.page = page
      this.getIpcorrect(this.queryinfo)
    },
    changepagesize (pagesize) {
      this.queryinfo.pagesize = pagesize
      this.getIpcorrect(this.queryinfo);
    },
    delok () {
      this.ipDel(this.delipinfo)
    }
  },
  created() {
    this.getIpcorrect(this.queryinfo);
  },
}
</script>

<style lang="less"  scoped>
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.demo-split{
  height: 200px;
  border: 1px solid #dcdee2;
}
.demo-split-pane{
  padding: 10px;
}

.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}

</style>
